
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
    components: {
        userImage: () => import("@/components/general/user-image.vue"),
        LayoutUser: () => import("@/layouts/presets/user.vue"),
    },
})
export default class PagePartner extends Vue {
    @Getter("auth/me") account!: CurrentAccount;
    @Getter("activity/viewing") activity!: Activity;

    reloadApp() {
        // @ts-ignore
        window.location.reload(true);
    }

    generateTooltip(content: string) {
        return { content: content, offset: [0, 12], delay: { show: 100, hide: 100 }, placement: "right" };
    }
}
